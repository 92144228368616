import { withWindow } from '@shared/utils/withWindow';
import { useTranslation, useRouterHelper } from '@shared/core';
import { useContactCollectorTelemetry } from '@apps/contactCollector/ContactCollector.telemetry';
import { contactCollectorRoutePaths } from '@apps/contactCollector';
import { useToast } from '@withjoy/joykit';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useNativeState } from '@shared/core/native';
import { dispatchContactCollectorCopyLinkToNativeApps } from '@shared/core/native/utils';
import { useUpdateContactCollectionMutation } from '@graphql/generated';
import { FormikProps } from 'formik';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { useEventInfo } from '@shared/utils/eventInfo';

export const DOMAIN_NAME = 'withjoy.com';
const PATH = 'contact';

export const useYourMagicLinkController = (magicLink: string, formik: FormikProps<ContactCollectorInputFields>) => {
  const { eventInfo } = useEventInfo();
  const { isNative } = useNativeState();
  const { value } = useFeatureValue('contactCollectionMobileCallbacksEnabled');
  const { toast } = useToast();
  const { manageButtonsClicked } = useContactCollectorTelemetry();
  const routeHelpers = useRouterHelper();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');
  const eventId = eventInfo?.eventId;
  const [updateContactCollectionMutation, { loading: isMutationLoading, error: mutationHasError }] = useUpdateContactCollectionMutation({
    variables: {
      id: formik.values.id!,
      data: {
        active: !formik.values.active,
        eventDateDisplayEnabled: formik.values.eventDateDisplayEnabled,
        mediaSource: formik.values.mediaSource,
        mediaType: formik.values.mediaType,
        message: formik.values.message,
        websiteLinkEnabled: formik.values.websiteLinkEnabled,
        eventId
      }
    },
    refetchQueries: ['GetContactCollectionByEventIdAndNamesByEventHandle']
  });

  const URL = `${DOMAIN_NAME}/${PATH}/${magicLink}`;
  const FULL_URL = `${window.location.origin}/${PATH}/${magicLink}`;

  const handleOnCopyClick = () => {
    if (isNative && value) {
      return dispatchContactCollectorCopyLinkToNativeApps(FULL_URL);
    }
    manageButtonsClicked({ label: 'copy link' });
    toast(tAdmin.linkCopied());
  };

  const handleOnModifyDesignClick = () => {
    manageButtonsClicked({ label: 'modify settings' });
    routeHelpers.goToExactRoute(contactCollectorRoutePaths.create.path, '?modify=true');
  };

  const handleOnPreviewClick = () => {
    manageButtonsClicked({ label: 'preview as guest' });
    withWindow(() => {
      window.open(`${window.location.origin}/contact/${magicLink}`, '_blank');
    });
  };

  const handleOnToggleChange = async () => {
    await updateContactCollectionMutation();
  };

  return {
    tAdmin,
    URL,
    FULL_URL,
    handleOnCopyClick,
    handleOnModifyDesignClick,
    handleOnPreviewClick,
    handleOnToggleChange,
    mutation: { isMutationLoading, mutationHasError }
  };
};
